$main: #272727;
$primary: #a78b58;
$green: #428b4d;
$red: #d30000;
$gray: #8b8b8b;
$medium-gray: #f2f2f2;
$light-gray: #fafafa;
$link: #2a4c7b;
$input-color: #eeeeee;
$dark-text: #272727;
$file-card-text: #575757;
$subheader: #b7b7b7;
$border-gray: #707070;
$divider-color: #f8f8f8;
$white: #ffffff;
$no-color: rgba(41, 137, 216, 0);
