@import './assets/styles/material-theme.scss';
@import 'ngx-toastr/toastr';
@import 'assets/styles/colors.scss';
@import './assets/styles/dynamic-background.scss';

body,
html {
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.container {
  max-width: 1400px;
  margin: auto;
}

.main-body {
  min-height: calc(100vh - 411px);
  min-height: -webkit-calc(100vh - 411px);
  min-height: -moz-calc(100vh - 411px);
}

@media screen and (max-width: 767px) {
  .main-body {
    min-height: calc(100vh - 473px);
    min-height: -webkit-calc(100vh - 473px);
    min-height: -moz-calc(100vh - 473px);
  }
}
.sticky-header {
  top: 0;
  position: sticky;
  z-index: 10;
}

.header-label {
  font-size: 30px;
  text-align: center;
  color: $white;

  @media screen and (max-width: 575px) {
    font-size: 25px !important;
  }
}

.sub-header-label {
  font-size: 25px;
  text-align: center;
  color: $primary;

  @media screen and (max-width: 575px) {
    font-size: 18px !important;
  }
}

.button-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: $white;

  @media screen and (max-width: 575px) {
    font-size: 18px !important;
  }
}

.bolder {
  text-shadow: 0 0 0 $primary, 0 0 0 $primary, 0 0 0 $primary, 0 0 0 $primary;
  font-size: 110%;
  letter-spacing: 0.3px;
}

.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.gray-color {
  color: $gray;
}

.main-color {
  color: $main !important;
}

.primary-color {
  color: $primary !important;
}

.primary-background {
  background-color: $primary;
}

.green-color {
  color: $green;
}

.green-background {
  background-color: $green;
}

.red-color {
  color: $red;
}

.blue-color {
  color: #2196f3;
}

.bg-main {
  background: $main !important;
}

.bg-gray {
  background: $medium-gray;
}

.bg-primary {
  background: $primary !important;
}

.bg-light-grey {
  background-color: #cccaca;
}

.bg-light-primary {
  background-color: #e8dfcd;
}

.mat-button-base {
  border-radius: 10px !important;
  font-weight: bolder;

  &:not(.register-button) {
    height: 40px !important;
  }
}

.mat-button-base:disabled {
  background-color: #d3c5ab !important;
}

.mat-medium-button {
  min-width: 140px !important;
}

.mat-long-button {
  min-width: 160px !important;
}

.mat-longer-button {
  min-width: 221px !important;
}

.black-border {
  border: 2px solid black !important;
}

.black-button {
  color: white !important;
  border: 2px solid white !important;
  background: $main !important;
}

.white-button {
  color: $main !important;
  border: 2px solid $main !important;
  background: white !important;
}

.primary-border {
  border-width: 3px !important;
  border-color: $primary !important;
}

.border-margin-fix {
  margin: -1px !important;
}

.text-gray {
  color: $gray !important;
}

.bold-text {
  font-family: 'Raleway-Bold';
  font-weight: 700;
}

.hover-bold:hover {
  font-weight: bold;
}

.white-input {
  color: white !important;

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white !important;
    background: white !important;
  }

  .mat-form-field-appearance-outline {
    height: 40px !important;
    font-size: 13px;
  }

  .mat-form-field-flex {
    height: 40px !important;
    padding-top: 3px;
  }

  .mat-form-field-wrapper {
    height: 40px !important;
  }

  .mat-form-field-infix {
    padding-top: 2px !important;
    padding-bottom: 3px !important;
    height: 40px !important;
  }

  .mat-input-element {
    line-height: 1 !important;
  }

  .mat-form-field {
    margin-bottom: 5px;
    width: 100% !important;

    .mat-form-field-outline-start {
      border-radius: 2px 0 0 2px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 2px 2px 0 !important;
    }

    .mat-form-field-outline-gap {
      border-top-style: solid !important;
      border-top-color: unset !important;
    }

    .mat-form-field-label-wrapper {
      top: -15px;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      opacity: 1;
    }

    .mat-form-field-wrapper {
      .mat-form-field-subscript-wrapper {
        position: inherit !important;
        overflow: hidden !important;
        margin: 0 6px 0 6px !important;
      }
    }
  }
}

.search-input {
  .mat-form-field-wrapper {
    margin: 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white !important;
    background: transparent !important;
    height: 40px;
  }

  .mat-input-element::placeholder {
    color: white;
  }

  .mat-input-element {
    line-height: 1.7 !important;
  }

  .mat-form-field-infix {
    height: 37px !important;
    display: flex;
    width: 136px;
  }
}

.flip {
  transform: scaleX(-1);
}

.auth-input {
  margin-bottom: 1rem;
  margin-top: 1rem;

  .mat-error {
    color: #f65f5f;
    font-size: 12px;
  }

  mat-label {
    font-size: 12px;
    color: $primary;
  }

  .mat-form-field-suffix .mat-icon {
    font-size: 16px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0.25rem;
  }

  .mat-form-field-flex {
    background: white;
    border-radius: 2px;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-standard .mat-form-field-flex {
    padding: 0.2rem 0.75rem 0;
  }

  .mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
    top: -12px;
    font-size: 10px;
    font-weight: bold;
  }
}

.admin-input {
  .mat-select-arrow {
    margin: 13px 0 0 0;
  }

  .mat-select-value {
    margin: 5px 0 0 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $dark-text !important;
  }

  .readonly {
    pointer-events: none;
    color: $subheader;
  }

  .mat-form-field-appearance-outline {
    height: 40px !important;
    font-size: 13px;
  }

  .mat-form-field-flex {
    height: 40px !important;
    padding-top: 3px;
  }

  .mat-form-field-wrapper {
    height: 40px !important;
  }

  .mat-form-field-infix {
    padding-top: 2px !important;
    padding-bottom: 3px !important;
    height: 40px !important;
  }

  .mat-input-element {
    line-height: 1 !important;
  }

  .mat-form-field {
    margin-bottom: 5px;
    width: 100% !important;
    background: white;

    .mat-form-field-outline-start {
      border-radius: 2px 0 0 2px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 2px 2px 0 !important;
    }

    .mat-form-field-outline-gap {
      border-top-style: solid !important;
      border-top-color: unset !important;
    }

    .mat-form-field-label-wrapper {
      top: -15px;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      opacity: 1;
    }

    .mat-form-field-wrapper {
      .mat-form-field-subscript-wrapper {
        position: inherit !important;
        overflow: hidden !important;
        margin: 0 6px 0 6px !important;
      }
    }
  }

  .date-input {
    line-height: 0.125 !important;
  }
}

.admin-textarea {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $dark-text !important;
  }

  .mat-form-field {
    width: 100% !important;

    .mat-form-field-infix {
      padding-top: 0 !important;

      .mat-input-element {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 1.4;
        color: $dark-text;
        opacity: 1;
      }
    }

    .mat-form-field-outline-start {
      border-radius: 2px 0 0 2px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 2px 2px 0 !important;
    }

    .mat-form-field-outline-gap {
      border-top-style: solid !important;
      border-top-color: unset !important;
    }

    .mat-form-field-label-wrapper {
      top: -15px;
      text-align: left;
      font-size: 14px;
      letter-spacing: 0;
      opacity: 1;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0 0 1em 0 !important;
      }

      .mat-form-field-subscript-wrapper {
        position: inherit !important;
        overflow: hidden !important;
        margin: 0 6px 0 6px !important;
      }
    }
  }
}

.modal-header-text {
  font-weight: bold !important;
  margin: 36px 0 20px !important;
}

.titlecase {
  text-transform: capitalize !important;
}

.label {
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  font-size: 13px;
  margin: 1rem 0 0.1rem;
  color: $gray;
  opacity: 1;
  width: 100%;
}

.label-no-uppercase {
  // font-weight: bold;
  text-align: left;
  font-size: 15px;
  margin: 1rem 0 0.1rem;
  color: $gray;
  opacity: 1;
  width: 100%;
}

.additional-label {
  text-transform: none;
  text-align: left;
  color: $gray;
  font-size: 11px;
  margin-left: 0.5rem;
}

.optional {
  font-size: 12px;
}

.custom-dialog-container .mat-dialog-container,
.custom-overhead-dialog-container {
  border-radius: 0 !important;
  padding: 0 !important;
}

.square-image {
  width: 135px;
  height: 135px;
}

.small-square-image {
  display: block;
  object-fit: cover;
  width: 72px;
  height: 72px;
}

.custom-container {
  padding: 24px 0;
  border-bottom: 2px solid $divider-color;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: $primary;
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px 2px;
}

.responsive-search {
  width: 50% !important;
}

.helper-text {
  font-size: 12px;
  color: $medium-gray;
  text-transform: uppercase;
}

.mat-card-header-text {
  margin: 0px !important;
}

.mat-card {
  border: 2px solid $medium-gray;
  border-radius: 3px !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.mat-card-image {
  height: 208px !important;
  margin: 0 !important;
  width: 100% !important;
}

.mat-card-image {
  height: 208px;
}

.group-card {
  border: 2px solid $divider-color;
  border-radius: 3px;
  background-color: white;
}

.mt-6 {
  margin-top: 6rem;
}

//--------------CSS FOR GROUP CARD--------------
.group-card-side-bar {
  width: 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-left: -2px;
}

.group-card-ended-or-closed {
  border: 2px solid $divider-color;
  border-radius: 20px;
  background-color: #e2e2e2;
}

.group-card-not-closed {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid $divider-color;
  border-radius: 3px;
  background-color: white;
}

.group-card-not-closed:hover {
  border: 2px solid $primary;
}

.group-grid-wrapper {
  display: grid;
  grid-template-columns: 14px calc(100% - 34px);
  grid-column-gap: 20px;
}
//--------------END CSS FOR GROUP CARD--------------

.cdk-overlay-container {
  z-index: 99 !important;
}

.break-word {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.link {
  font-weight: bold;
  cursor: pointer;
  color: $primary !important;
}

.sticky-div {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  .responsive-search {
    width: 100%;
  }

  .custom-dialog-container {
    height: 100vh;
    width: 100vw !important;
    max-width: none !important;
  }

  .custom-overhead-dialog-container {
    width: 90vw !important;
    max-width: none !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.gray-label {
  text-transform: uppercase;
  color: $gray;
  font-size: 13px;
  margin: 0 !important;
  font-weight: 600;
  line-height: 17px;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow: hidden !important;
}

.main-text {
  font-size: 18px;
  line-height: 26px;
}

.mat-main {
  background-color: $main !important;
  color: white;
}

.mat-white {
  background-color: white !important;
  border: 2px solid $main !important;
  color: $main;
}

.truncated-single-line {
  display: -webkit-box !important;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  white-space: normal !important;
  text-overflow: ellipsis;
}

@for $i from 2 through 6 {
  .truncated-#{$i}-lines {
    display: -webkit-box !important;
    max-width: 100%;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@for $i from 1 through 46 {
  .font-#{$i} {
    font-size: $i + 0px !important;
  }
}

@for $i from 1 through 5 {
  .gap-#{$i} {
    gap: $i + 0em;
  }
}

@for $i from 1 through 9 {
  .opacity-#{$i} {
    opacity: $i/10;
  }
}

.col-header {
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  color: $gray;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.mat-button:not(.register-button) .mat-button-wrapper,
.mat-flat-button .mat-button-wrapper {
  display: -webkit-box !important;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  white-space: normal;
  text-overflow: ellipsis;
}

.toast-container .ngx-toastr {
  width: 320px;
}

@media screen and (max-width: 407px) {
  .register-button {
    .mat-button-wrapper {
      white-space: pre-wrap !important;
      word-wrap: break-word !important;
    }
    line-height: 24px !important;
  }
}

.small-text {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  .text-md-nowrap {
    white-space: nowrap !important;
  }
}

.filter-button {
  width: 100%;
  max-width: 221px;
}

.sort-by-button {
  width: 118px;
}

.local-time {
  color: $gray;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: -10px;
}

.modal-header-name {
  font-size: 28px !important;

  @media screen and (max-width: 1300px) {
    font-size: 24px !important;
    line-height: 38px !important;
  }

  @media screen and (max-width: 1150px) {
    font-size: 22px !important;
    line-height: 32px !important;
  }

  @media screen and (max-width: 450px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.mat-mobile-button {
  width: 80%;
}

.mat-button-toggle-label-content {
  line-height: 36px !important;
}

.mat-button-toggle-checked .mat-button-toggle-label-content {
  background-color: $primary;
  color: white;
}

.big-text {
  font-size: 30px;
}

.disabled-text {
  color: #8b8b8b;
}

.disabled-card {
  pointer-events: none;
  // opacity: 0.4;
}

.underline {
  border-bottom: 3px solid;
  width: 50%;
  min-width: 240px;
  padding-bottom: 10px;

  @media screen and (max-width: 575px) {
    width: 85%;
  }
}

.vertical-bar {
  border-left: 2px solid white;
  height: auto;
}

.mat-tooltip {
  border: 2px solid $primary;
  border-radius: 5px;
  color: $primary !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px !important;
  padding: 10px 20px 10px 20px !important;
  max-width: 400px !important;
}

.pulse-button {
  position: relative;
  box-shadow: 0 0 0 0 rgba(167, 139, 88, 0.5);
  animation: pulse 1.5s infinite;
}
.pulse-button:hover {
  animation: none;
}

.warning-text {
  color: #ff8000;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(167, 139, 88, 0.5);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(167, 139, 88, 0.5);
  }
}

.modal-backdrop {
  display: none;
}

.iti {
  width: 100%;
}

.width-fit {
  width: fit-content !important;
}

// .title {
//   font-size: 24px !important;
// }

// .sub-title {
//   font-size: 20px !important;
// }

@media only screen and (max-width: 639px) {
  .title {
    font-size: 24px !important;
  }

  .sub-title {
    font-size: 20px !important;
  }
}

.browse {
  font-weight: bold;
  color: $primary;
}

.mat-menu-panel:has(.import-message) {
  min-height: auto !important;
  min-width: auto !important;
  max-width: none !important;
}

.modal-loading-spinner {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
}

.cdk-overlay-pane {
  transform: none !important;
  box-sizing: content-box;

  .mat-select-panel {
    margin: 30px 0 25px -15px;
  }
}
